.myorder-modal-cancel {
  position: absolute;
  top: 30%;
  left: 10%;
  right: 10%;
  background-color: white;
  transform: 'translate(-50%, -50%)';
  border-radius: 20px;
  border: #00B9ED 1px solid;
  padding-bottom: 1%;
}

.confirmation-order-modal {
  position: absolute;
  top: 30%;
  left: 10%;
  right: 10%;
  background-color: white;
  transform: 'translate(-50%, -50%)';
  border-radius: 20px;
  border: #00B9ED 1px solid;
  margin-top: 1%;
  padding-bottom: 1%;
}

.loadinglocker-modal {
  position: absolute;
  top: 30%;
  left: 10%;
  right: 10%;
  background-color: white;
  transform: 'translate(-50%, -50%)';
  border-radius: 20px;
  border: #00B9ED 1px solid;
  margin-top: 1%;
  padding-bottom: 1%;
}

.background {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-background {
  width: 100%;
  height: 100vh;
  background-color: white;
  color: black;
  border-radius: 20px;
  padding: 1%;
  margin-left: -5%;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.alert-message {
  line-height: 5px;
}

.modal-word1 {
  line-height: 5px;
  color: #023A51;
  font-size: 16px;
  font-weight: bold;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.modal-word2 {
  line-height: 25px;
  color: #023A51;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.modal-button1 {
  background: #00B9ED;
  border-radius: 10px;
  line-height: 30px;
  width: 80%;
  color: white;
  /* border-color: white; */
  border-style: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-confirm-button1 {
  background: #00B9ED;
  border-radius: 10px;
  line-height: 30px;
  width: 80%;
  color: white;
  /* border-color: white; */
  border-style: none;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.unlock-button1 {
  background: #00B9ED;
  font-size: 20px;
  border-radius: 10px;
  line-height: 50px;
  width: 80%;
  color: white;
  /* border-color: white; */
  border-style: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.otp-word {
  color: #023A51;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0.04em;
  text-align: center;
}

.unlock-logo {
  margin-top: 35%;
}

.modal-arrowback {
  position: absolute;
  transform: translate(60%, -420%);

}

.modal-error {
  color: red;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  font-weight: bold;
}