.support-body {
  min-height: 100vh;
  width: 100%;
  padding-top: 1rem;
  /* padding-bottom: 3rem; */
  background-color: #e1ecef;
}

.support-text {
  font-size: 30px;
  width: 80%;
  font-weight: bold;
  color: #023a51;
  margin-top: 1rem;
}

.support-arrow {
  position: absolute;
  /* margin-top: 10px; */
  margin-left: 15px;
}
.support-error-msg {
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: red;
  font-size: 15px;
  margin-top: 20px;
}
.support-success-msg {
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: green;
  font-size: 15px;
  margin-top: 20px;
}
.profile-detail {
  width: 85%;
  background-color: #ffffff;
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 15%;
  margin-left: 50%;
  padding-bottom: 1rem;
  transform: translateX(-50%);
}

.support-word1 {
  font-size: 18px;
  color: #023a51;
  font-weight: bold;
  font-family: Roboto;
  text-align: left;
  padding-bottom: 2%;
  padding-top: 3%;
}

.profile-details-input-box {
  font-size: 20px;
  color: #023a51;
  width: 100%;
  border: 20px;
  height: 2.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.profile-details-input-boxs {
  font-size: 20px;
  background-color: #ffffff;
  color: #023a51;
  border: 20px;
  height: 2.5rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.profile-details-input-box-text-area {
  font-size: 20px;
  color: #99ca3b;
  width: 100%;
  height: 15vh !important;
  border: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.support-button1 {
  background: #00b9ed;
  border-radius: 10px;
  line-height: 40px;
  width: 80%;
  color: white;
  /* border-color: white; */
  border-style: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
